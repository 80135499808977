import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {
  PartnerContact,
  PartnerContactVariables,
} from "./__generated__/PartnerContact";

const PartnerContactMutation = gql`
  mutation PartnerContact($token: String!, $message: String!) {
    partnerContactSalesRep(token: $token, message: $message) {
      error {
        code
        message
      }
    }
  }
`;

export const usePartnerContact = () => {
  const token = useUserToken();
  const [sendMessage, result] = useMutation<
    PartnerContact,
    PartnerContactVariables
  >(PartnerContactMutation);

  return {
    ...result,
    error:
      result.error ||
      (result.data &&
        result.data.partnerContactSalesRep &&
        result.data.partnerContactSalesRep.error),
    sendMessage: async (message: string) => {
      const { data, errors, ...res } = await sendMessage({
        variables: {
          token,
          message,
        },
      });
      return {
        ...res,
        data,
        errors,
        error: errors && errors[0]
      }
    },
  };
};
