import {
  Alert,
  Card,
  colors,
  DelegateInfo,
  DelegateInfoMail,
  Form,
  IconArrowL10,
  Link,
  LittersAddHeader,
  Textarea,
  useInputProps,
  Wrapper,
} from "@royalcanin-be-partner-portal/ui-kit";
import { Placeholder } from "@royalcanin-be-partner-portal/ui-kit/src/Placeholder";
import React, { useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { Col, Row } from "react-styled-flexboxgrid";
import { object, string } from "yup";
import { Layout } from "../components/Layout";
import { useCurrentPartnerDetails } from "../lib/hooks/usePartnerDetails";
import { usePartnerContact } from "../lib/hooks/usePartnerContact";
import { FORM_ERROR } from "final-form";
import { withPage } from "../lib/withPage";

const messages = defineMessages({
  inputMessageLabel: {
    id: "form.contact.message.label",
    defaultMessage: "Votre message",
  },
});

const Contact = () => {
  const { data: partner, loading } = useCurrentPartnerDetails();
  const { sendMessage } = usePartnerContact();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const intl = useIntl();

  return (
    <Layout>
      <Wrapper>
        <Row center="xs">
          <Col xs={12} sm={12} md={8}>
            <Form
              loading={loading}
              initialValues={{
                message: "",
              }}
              schema={object().shape({
                message: string().required(),
              })}
              onSubmit={async ({ message }: { message: string }, { reset }) => {
                setShowSuccessMessage(false);
                const { error } = await sendMessage(message);
                if (error) {
                  return {
                    [FORM_ERROR]: error.message,
                  };
                }

                setTimeout(reset);
                setShowSuccessMessage(true);
                return;
              }}
              render={({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit}>
                  <LittersAddHeader
                    backLink={
                      <Link
                        href="/information"
                        borderless
                        prefix={
                          <IconArrowL10
                            width={10}
                            height={20}
                            fill={colors.primary}
                          />
                        }
                      >
                        <FormattedMessage
                          id="website.contact.back"
                          defaultMessage="Retour"
                        />
                      </Link>
                    }
                    submitDisabled={
                      loading || submitting || pristine || !partner?.salesrep
                    }
                    submitLabel={
                      <FormattedMessage
                        id="button.label.send"
                        defaultMessage="Envoyer"
                      />
                    }
                  >
                    <FormattedMessage
                      id="website.contact.contact_my_delegate"
                      defaultMessage="Contacter mon délégué"
                    />
                  </LittersAddHeader>
                  <Card spacing="md">
                    <DelegateInfo
                      title={
                        loading ? <Placeholder /> : partner?.delegateName || ""
                      }
                    >
                      <DelegateInfoMail>
                        {loading ? <Placeholder /> : partner?.salesrep || ""}
                      </DelegateInfoMail>
                    </DelegateInfo>

                    {submitError && (
                      <Alert type="error" style={{ marginTop: 30 }}>
                        {submitError}
                      </Alert>
                    )}

                    {showSuccessMessage && (
                      <div>
                        <Alert type="success" style={{ marginTop: 30 }}>
                          <FormattedMessage
                            id="website.contact.successMessage"
                            defaultMessage="Votre message a été envoyé à votre responsable commercial."
                          />
                        </Alert>
                      </div>
                    )}

                    <Textarea
                      style={{ marginTop: 30 }}
                      {...useInputProps({ name: "message" })}
                      placeholder={intl.formatMessage(
                        messages.inputMessageLabel,
                      )}
                      disabled={!partner?.salesrep}
                    />
                  </Card>
                </form>
              )}
            />
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

export default withPage(Contact);
